<template>
  <div>
    <a-modal
      :visible="visibleModalPassword"
      @cancel="cancel()"
      @ok="updatePassword"
    >
      <a-form :form="formPassword" class="container" @submit="updatePassword">
        <div class="row">
          <div class="col-lg-6">
            <a-form-item :label="$t('enseignant.usernameEmail')">
              <a-input
                :placeholder="$t('enseignant.usernameEmail')"
                type="text"
                v-decorator="[
                  'username',
                  {
                    initialValue: credentials.email,
                    rules: [
                      {
                        required: true,
                        message: $t('requis.usernameEmail'),
                      },
                      {
                        validator: validateNoWhitespace,
                        message: $t('requis.noWhitespace'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item :label="$t('profil.mdp')">
              <a-input
                :placeholder="$t('profil.mdp')"
                type="text"
                v-decorator="[
                  'password',
                  {
                    initialValue: credentials.password,
                    rules: [
                      {
                        required: true,
                        message: $t('requis.mdp'),
                      },
                      {
                        min: 4,
                        message: $t('requis.carac'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item :label="$t('utilisateur.confirmerMdp')">
              <a-input
                :placeholder="$t('utilisateur.confirmerMdp')"
                type="text"
                v-decorator="[
                  'confirmPassword',
                  {
                    initialValue: credentials.password,
                    rules: [
                      {
                        required: true,
                        message: $t('requis.confirmation'),
                      },
                      {
                        min: 4,
                        message: $t('requis.carac'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>
        <vue-qr
          :text="qrData"
          logoSrc="/favicon.png"
          :callback="getQrImage"
          qid="teacher"
        ></vue-qr>
        <a-button
          type="button"
          class="btn btn-primary"
          @click="generatePdf"
          style="position: relative; z-index: 99"
          ><i class="fa fa-address-card-o mr-2" aria-hidden="true" />

          {{ $t("action.imprimer") }}
        </a-button>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable */

import apiClient from "@/services/axios";
import { mapState } from "vuex";
import VueQr from "vue-qr";
import JsPDF from "jspdf";

export default {
  computed: {
    ...mapState(["settings"]),
  },
  components: {
    VueQr,
  },
  props: [
    "idTeacher",
    "credentials",
    "visibleModalPassword",
    "firstName",
    "lastName",
  ],
  data() {
    return {
      formPassword: this.$form.createForm(this),
      loadingUtilisateur: false,
      qrDataImage: "",
      qrData: "",
    };
  },
  created() {
    this.qrData = btoa(
      this.idTeacher +
        "." +
        this.settings.activeBuilding +
        "." +
        this.settings.base_url
    );
  },
  methods: {
    validateNoWhitespace(rule, value, callback) {
      if (/\s/.test(value)) {
        callback(new Error("Username cannot contain whitespace"));
      } else {
        callback();
      }
    },
    cancel() {
      this.visibleModalPassword = false;
      this.$emit("cancel");
    },
    getQrImage(dataUrl) {
      this.qrDataImage = dataUrl;
    },
    updatePassword(e) {
      console.log("idTeacher", this.idTeacher);
      this.loadingUtilisateur = true;
      e.preventDefault();
      this.formPassword.validateFieldsAndScroll((_err, values) => {
        if (values.password != values.confirmPassword) {
          this.$message.error(this.$t("error.verifier"));
          this.loadingUtilisateur = false;
        } else {
          apiClient
            .patch("/teachers/changecredentials/" + this.idTeacher, {
              username: values.username,
              password: values.password,
            })
            .then(() => {
              this.$message.success(this.$t("success.utilisateurMSA"));
              this.visibleModalPassword = false;
            })
            .catch(() => {
              this.$message.error(this.$t("error.modif"));
            });
          this.loadingUtilisateur = false;
        }
      });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    generatePdf() {
      this.$gtag.event("Imp information Edteacher", {
        event_category: "Impression PDF",
        event_label: "Personnel - ListeEnseignants section",
        value: 1,
      });

      const arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      const date = new Date();
      const titre = "Edteacher : Informations de connexion";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(65, 70, titre);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(15);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      doc.setFontSize(12);
      doc.text(40, 100, "Nom d'utilisateur : " + this.credentials.email);
      doc.text(40, 120, "Mot de passe : " + this.credentials.password);
      doc.text(
        40,
        140,
        "Vous pouvez s'identifier en utlisant ce Qr code dans l'application mobile :"
      );

      doc.addImage(this.qrDataImage, "JPEG", 80, 150, 45, 45);

      //end page numbering
      doc.save(this.lastName + "_" + this.firstName + ".pdf");
    },
  },
};
</script>
